<script>
import { VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners'
import {with_acrf_token} from "@/methods";

function parseISO8601String(dateString) {
  var timebits = /^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2})(?::([0-9]*)(\.[0-9]*)?)?(?:([+-])([0-9]{2})([0-9]{2}))?/;
  var m = timebits.exec(dateString);
  var resultDate;
  if (m) {
    var utcdate = Date.UTC(parseInt(m[1]),
        parseInt(m[2])-1, // months are zero-offset (!)
        parseInt(m[3]),
        parseInt(m[4]), parseInt(m[5]), // hh:mm
        (m[6] && parseInt(m[6]) || 0),  // optional seconds
        (m[7] && parseFloat(m[7])*1000) || 0); // optional fraction
    // utcdate is milliseconds since the epoch
    if (m[9] && m[10]) {
      var offsetMinutes = parseInt(m[9]) * 60 + parseInt(m[10]);
      utcdate += (m[8] === '+' ? -1 : +1) * offsetMinutes * 60000;
    }
    resultDate = new Date(utcdate);
  } else {
    resultDate = null;
  }
  return resultDate;
}

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VclCode,
    HalfCircleSpinner
  },
  props: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/banmanager/list', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.banlists = data.banlists;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    createBanlist() {
      let ref = this;
      ref.submitted = true;
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/banmanager/list', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if(response.ok || response.status === 400){
                return response.json();
              } else {
                if(response.status === 429) {
                  ref.submitted = false;
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              if(!data) return;
              ref.submitted = false;
              if(data.status) {
                ref.getData();
                this.$swal({
                  icon: 'success',
                  text: this.$t('banmanager.list.new.success')
                });
              } else {
                if(data.error === 'limit-reached') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('banmanager.list.new.error.limit')
                  });
                } else if(data.error === 'restricted') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('banmanager.list.new.error.restricted')
                  });
                } else {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('banmanager.list.new.error.generic')
                  });
                }
              }
            })
            .catch(error => {
              ref.submitted = false;
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('banmanager.list.new.error.generic')
              });
            });
      }).catch(error => {
        ref.submitted = false;
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    }
  },
  created() {
    this.getData()
  },
  data() {
    return {
      ready: false,
      error: false,
      banlists: [],
      submitted: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <template v-if="banlists.length === 0">
        <div class="row">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-empty-set info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('banmanager.list.empty.title')}}</h3>
                  <h5 class="text-muted">{{$t('banmanager.list.empty.message')}}</h5>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col">
                  <button class="btn btn-primary btn-lg text-uppercase" :disabled="submitted ? true : false" v-on:click="createBanlist()">
                    <h5 class="mb-0">
                      <half-circle-spinner
                          v-if="submitted"
                          :animation-duration="900"
                          :size="18"
                          class="align-middle d-inline-block"
                      />
                      {{$t('banmanager.list.new.button')}}
                    </h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-3">
          <div class="col">
            <button class="btn btn-primary btn-lg text-uppercase float-right" :disabled="submitted ? true : false" v-on:click="createBanlist()">
              <h5 class="mb-0">
                <half-circle-spinner
                    v-if="submitted"
                    :animation-duration="900"
                    :size="18"
                    class="align-middle d-inline-block"
                />
                {{$t('banmanager.list.new.button')}}
              </h5>
            </button>
          </div>
        </div>
        <div class="row ml-lg-5 mr-lg-5">
          <div class="col-lg-3 col-sm-12 h-100" v-for="banlist in banlists" :key="banlist.id">
            <router-link :to="{name: 'banlist', params: {banlist_id: banlist.id}}">
              <div class="card text-white h-100">
                <div class="card-body h-100">
                  <div class="position-relative">
                    <div class="float-left badge badge-primary" v-if="banlist.is_owner">
                        <h6 class="mb-0">
                          <i class="fad fa-crown"></i>
                          {{$t('banmanager.list.owner')}}
                        </h6>
                    </div>
                    <!--
                    <div class="float-right badge badge-success" v-if="banlist.is_verified">
                      <h6 class="mb-0">
                        <i class="fad fa-badge-check"></i>
                        Verified
                      </h6>
                    </div>
                    -->
                  </div>
                  <div class="row w-100 p-0 m-0 justify-content-center">
                    <div class="col-lg-12 text-center">
                      <h3 class="pb-0 mt-5 mb-3 text-truncate">
                        {{banlist.identifier}}
                      </h3>
                    </div>
                  </div>

                </div>
                <div class="card-footer">
                  <div class="row text-center">
                    <div class="col">
                      <p class="text-muted text-truncate mb-2">
                        {{$t('banmanager.list.ban_count')}}
                      </p>
                      <h5 class="mb-0">
                        {{ banlist.ban_count }}
                      </h5>
                    </div>
                    <div class="col">
                      <p class="text-muted text-truncate mb-2">
                        {{$t('banmanager.list.followers')}}
                      </p>
                      <h5 class="mb-0">
                        {{ banlist.followers }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12 row justify-content-center">
          <div class="col-lg-6 col-sm-12">
            <div class="card border border-danger">
              <div class="card-header bg-transparent border-danger">
                <h5 class="my-0 text-danger">
                  <i class="far fa-exclamation-circle mr-3"></i>
                  {{$t('error.server.generic.title')}}
                </h5>
              </div>
              <div class="card-body pt-0">
                <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-3 col-sm-12" v-for="index in 5" :key="index">
          <div class="card">
            <div class="card-body">
              <vcl-code :width="300" :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

</template>
